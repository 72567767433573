/* You can add global styles to this file, and also import other style files */
@use "sass:map";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    xs: 100%,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

*{
    font-family: 'Ubuntu', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

html{
    font-size: 16px;
}
ul{
    margin-left: .75rem;
    li{
        margin-left: .75rem;
    }
}

@each $name, $width in $grid-breakpoints {
    @media (min-width: $width) { 
      .container {
        width: map.get($container-max-widths, #{$name});
        margin: auto;
        padding-inline: 1rem;
      }
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
